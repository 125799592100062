import { Content } from 'components/atoms/Content';
import { RadioInput } from 'components/atoms/RadioInput';
import hexRgb from 'hex-rgb';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { SectionTitle } from '../SectionTitle';
import Image from 'next/image';
import { Breakpoints } from 'styles/theme';
import { ShippingOptionsTable } from './ShippingOptionsTable';
import { carrierLogoMap } from './common';
import { useEffect, useRef } from 'react';
import { formatMoney } from '@magicship/utils/money';
import { ShippingOption, ShippingRatesResult } from '~graphql/API';

type Props = {
    data: ShippingRatesResult;
    selectedOption: ShippingOption | null;
    onChange: (option: ShippingOption) => void;
    disabled?: boolean;
    showOriginalPrice?: boolean;
    hideAlias?: boolean;
    scrollIntoView?: boolean;
    isPOBoxAddress: boolean;
    isLikelyRemoteAddress: boolean;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => hexRgb(theme.colors.secondary, { alpha: 0.2, format: 'css' })};
    align-self: stretch;
    justify-items: flex-start;
    width: 100%;
    padding: 12px 0 32px;

    @media ${Breakpoints.lg} {
        background: none;
    }
`;

const StyledContent = styled(Content)`
    align-items: flex-start;
`;

const ItemRow = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 2rem;
`;

const ItemInfo = styled.div`
    display: flex;
    flex-grow: 1;
    margin-left: 0.5rem;
`;

const ItemsMobile = styled.div`
    width: 100%;

    @media ${Breakpoints.lg} {
        display: none;
    }
`;

const ItemsTableContainer = styled.div`
    background: ${({ theme }) => hexRgb(theme.colors.secondary, { alpha: 0.2, format: 'css' })};
    display: none;
    padding: 20px;
    align-self: stretch;

    @media ${Breakpoints.lg} {
        display: flex;
    }
`;

const ServiceName = styled.div`
    cursor: pointer;
    margin-bottom: 8px;
`;

const StyledRadioButton = styled(RadioInput)`
    margin-top: 2px;
`;

const StyledOriginalPrice = styled.span`
    opacity: 0.5;
    text-decoration: line-through;
    margin-left: 1rem;
`;

const PriceContainer = styled.div`
    margin-top: 1rem;
`;

const ServiceNameContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ErrorRenderer = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 0;

    > div {
        flex-shrink: 0;
    }

    > span {
        margin-left: 1rem;
        flex-grow: 1;
    }
`;

const AddressNoteMessageBase = styled.div<{ theme: { colors: { danger: string } } }>`
    color: ${({ theme }) => theme.colors.danger};
    text-align: center;
    margin-bottom: 1rem;
`;

const PoBoxMessage = styled(AddressNoteMessageBase)``;

const RemoteAddressMessage = styled(AddressNoteMessageBase)``;

const ProviderInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;
    align-items: flex-end;
`;

const EmptyIndicator = styled.div`
    padding: 2rem;
    display: flex;
    justify-content: center;
    min-height: 45px;
    align-items: center;
`;

export const ShippingOptions: React.FunctionComponent<Props> = ({
    data,
    selectedOption,
    onChange,
    disabled,
    showOriginalPrice,
    hideAlias,
    scrollIntoView,
    isPOBoxAddress,
    isLikelyRemoteAddress,
}: Props) => {
    const { t } = useTranslation('shipping');
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (containerRef.current && scrollIntoView) {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [scrollIntoView]);
    const hasErrors = Boolean(data.errors?.length);
    const hasRates = Boolean(data.rates?.length);
    return (
        <Container ref={containerRef}>
            {hasErrors && (
                <Content>
                    {data.errors?.map((x) => {
                        const logo = carrierLogoMap[x.service];
                        return (
                            <ErrorRenderer key={x.service}>
                                <Image src={logo} alt={x.service} width="132" height="68" objectFit="contain" />
                                <span>{x.message}</span>
                            </ErrorRenderer>
                        );
                    })}
                </Content>
            )}
            {hasRates && (
                <StyledContent>
                    <SectionTitle>{t('Pricing')}</SectionTitle>
                    {isPOBoxAddress && (
                        <PoBoxMessage>
                            {t(
                                'NOTE: This seems to be a P.O. Box address, which ONLY Canada Post can deliver to. If there is no actual Street Address, make sure to use Canada Post. If there is an actual Street Address, use the cheapest carrier.'
                            )}
                        </PoBoxMessage>
                    )}

                    {isLikelyRemoteAddress && !isPOBoxAddress && (
                        <RemoteAddressMessage>
                            {t(
                                'NOTE: This seems to be a remote address, use ICS only if the rate is at least ~$10 cheaper.'
                            )}
                        </RemoteAddressMessage>
                    )}
                    <ItemsMobile>
                        {data.rates.map((x: ShippingOption, index) => {
                            const code = `${x.providerId}-${x.service}-${x.serviceCode}`;
                            const logo = carrierLogoMap[x.service];
                            const isSelected =
                                x.providerId === selectedOption?.providerId &&
                                x.service === selectedOption?.service &&
                                x.serviceCode === selectedOption?.serviceCode;
                            return (
                                <ItemRow key={code} onClick={() => onChange(x)}>
                                    <StyledRadioButton
                                        kind="secondary"
                                        name="service-option"
                                        value={index.toString()}
                                        onChange={() => onChange(x)}
                                        isSelected={isSelected}
                                        fill="contrast"
                                    />
                                    <ItemInfo>
                                        <ServiceNameContainer>
                                            <div>
                                                <ServiceName onClick={() => onChange(x)}>{x.serviceName}</ServiceName>
                                                <div>
                                                    {x.daysInTransit
                                                        ? `${x.daysInTransit} ${
                                                              x.daysInTransit === '1' ? t('day') : t('days')
                                                          }`
                                                        : ''}
                                                </div>
                                            </div>
                                            <PriceContainer>
                                                {formatMoney(x.price)}
                                                {showOriginalPrice && (
                                                    <StyledOriginalPrice>
                                                        {x.originalPrice ? formatMoney(x.originalPrice) : ''}
                                                    </StyledOriginalPrice>
                                                )}
                                            </PriceContainer>
                                        </ServiceNameContainer>
                                        <ProviderInfo>
                                            {logo && (
                                                <Image
                                                    src={logo}
                                                    alt={x.service}
                                                    width="132"
                                                    height="68"
                                                    objectPosition="top"
                                                    objectFit="contain"
                                                />
                                            )}
                                            {x.providerName && !hideAlias && <span>{x.providerName}</span>}
                                        </ProviderInfo>
                                    </ItemInfo>
                                </ItemRow>
                            );
                        })}
                    </ItemsMobile>
                    <ItemsTableContainer>
                        <ShippingOptionsTable
                            showOriginalPrice={showOriginalPrice}
                            data={data.rates}
                            selectedOption={selectedOption}
                            onChange={onChange}
                            disabled={disabled}
                            hideAlias={hideAlias}
                        />
                    </ItemsTableContainer>
                </StyledContent>
            )}
            {!hasErrors && !hasRates && <EmptyIndicator>{t('No shipping options available')}</EmptyIndicator>}
        </Container>
    );
};
